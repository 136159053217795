/* global braintree paypal GB_handlePay */
import { mapMutations } from 'vuex'
import apiReport from 'public/src/pages/common/apiReport'
// import unPay from 'public/src/pages/checkout/components/unifiedPay'
import { abtservice } from 'public/src/services/abt'
import { asyncLoadFile, parseQueryString } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { PPGA_CHECK_TYPE, AFTERPAY_CHECK_TYPE, SIGNUP_CHECK_TYPE } from '../../common/constants'
import Vue from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import errorReport from 'public/src/pages/common/handlePay/log'
import { Toast as $toast } from '@shein/sui-mobile'
import { SILogger } from 'public/src/pages/common/monitor/index.js'

const { host, langPath, IS_RW } = gbCommonInfo

daEventCenter.addSubscriber({ modulecode: '2-31' })

const channelList = ['klarna', 'paypal']
let billno = ''
// let giftCardPath = location.pathname.indexOf('/giftcard') > -1 ? '/giftcard' : ''
let paypalSdkTimeout = null
const paypalChannelSdks = {
  'PayPal-GApaypal': [
    //Load the client component
    'https://js.braintreegateway.com/web/3.85.2/js/client.min.js',
    //Load the PayPal Checkout component
    'https://js.braintreegateway.com/web/3.85.2/js/paypal-checkout.min.js',
    //手机设备数据
    'https://js.braintreegateway.com/web/3.85.2/js/data-collector.min.js'
  ],
  'PayPal-Venmo': [
    //Load the client component
    'https://js.braintreegateway.com/web/3.85.2/js/client.min.js',
    //Load the PayPal Checkout component
    'https://js.braintreegateway.com/web/3.85.2/js/venmo.min.js',
    //手机设备数据
    'https://js.braintreegateway.com/web/3.85.2/js/data-collector.min.js'
  ],
  'PayPal-bnpl': [
    //Load the client component
    'https://js.braintreegateway.com/web/3.85.2/js/client.min.js',
    //Load the PayPal Checkout component
    'https://js.braintreegateway.com/web/3.85.2/js/paypal-checkout.min.js',
    //手机设备数据
    'https://js.braintreegateway.com/web/3.85.2/js/data-collector.min.js'
  ],
}

export default {
  data() {
    return {
      showWidgetForm: 1, // 0：Widget加载失败，不展示直接隐藏 1:初始态 2： Widget加载成功
      payInlineSessionInfoCache: {},
      payInlineSessionInfo: {},
      needPayInlinePaymentMethods: [],
      chosenChannel: '', //目前选中支付方式的渠道
      paypalGaBtn: false, //是否已经渲染过paypal按钮
      paypalDeviceId: '',
      orderData: null,
      paypalGaPayLoad: null,
      nowPageType: 0, //1：下单页，2：订单详情，3：礼品卡下单页，4：礼品卡二次下单页，5：订单列表页, 6: 付费会员购买页, 7: 超省卡单独购，8: 超省卡订单详情
      paypalSdkLauching: false, //paypal sdk是否正在初始化
      sdkTokenReqing: false,
      nowInlineCacheKey: '',
      nowPaypalCacheKey: '',
      paypalSdkLoadSuccess: {}, // paypal直连支付渠道sdk加载状态
      paypalGaVault: {
        tipShow: false,
        radioType: PPGA_CHECK_TYPE.CHECKED, //用于展示哪个勾选
        type: PPGA_CHECK_TYPE.CHECKED, //实际应用的勾选
        visibleArrow: true, // 是否展示valuting选择弹窗
        express: false // 是否使用快捷选中方式 - 未签约，且允许选择是否记住卡号
      },
      afterPayVault: {
        valid: true,
        tipShow: false,
        radioType: AFTERPAY_CHECK_TYPE.CHECKED,	//用于展示哪个勾选
        type: AFTERPAY_CHECK_TYPE.CHECKED,			//实际应用的勾选
        visibleArrow: true, // 是否展示valuting选择弹窗
        switch: false, // 是否开启token支付
        title: 'A', // 支付方式标题
      },
      signupPayVault: {},
      paypalVenmoBtnShow: false, //是否已经渲染过paypal 展示Venmo按钮
      paypalVenmoDeviceId: '',
      paypalVenmoPayLoad: null,
      venmoInstance: null,
      processList: [], //用于记录paypal-ga的支付步骤走到了哪些
      signupDrawerConfig: { // 非卡token一次支付弹窗配置
        show: false,
        paymentCode: '',
      },
      defaultInlineMethods: ['PayPal-GApaypal', 'PayPal-Venmo', 'PayPal-bnpl'], // 默认走inline流程，不依赖abt
      nowCheckoutScene: '', // 当前下单页场景 Checkout：普通订单下单页，OrderDetail：普通订单详情，GiftCard：礼品卡下单页，GiftCardAgain：礼品卡二次下单页，OrderList：订单列表页, BuyPrime: 付费会员购买页, Xtra: 超省卡单独购，XtraDetail: 超省卡订单详情
      totalPriceAmount: '',
      countryCode: '',
      vipOrderSource: 0, // 会员标识，0/空-普通订单 1-用户签约购买自动续费
      paypalMessageShow: false, //是否显示paypal message
    }
  },
  watch: {
    currencyCode: {
      handler(value) {
        // 若不支持此币种
        if (!this.isSupportPaypalBnpl(value)) {
          // 显示 静态 message
          this.paypalMessageShow = false
          console.log('messgae 不支持此币种', this.paypalBnplCurrencySupport)
          // return
        } else {
          // 显示 paypalbnpl message
          // this.getPayInlineSession()
          this.paypalMessageShow = true
          console.log('messgae 支持此币种', this.paypalBnplCurrencySupport)
        }
      },
      immediate: true
    }
  },
  computed: {
    paypalBtnShow() {
      return (
        this?.chosenChannel == 'paypal' && 
        (this?.status?.cardPay === 'PayPal-GApaypal' || this?.paymentMethod?.paymentMethod === 'PayPal-GApaypal') &&
        this.showWidgetForm == 2 &&
        !this.isContinueSign
      )
    },
    paypalBnplBtnShow(){
      return this?.chosenChannel == 'paypal' && (this?.status?.cardPay === 'PayPal-bnpl' || this?.paymentMethod?.paymentMethod === 'PayPal-bnpl') && this?.showWidgetForm == 2
    },
    ppgvAccountInfo() {
      let paymentMethods = []
      // 下单
      if (
        [1, 3, 4, 6].includes(this.nowPageType) ||
        ['Xtra'].includes(this.nowCheckoutScene)
      ) {
        paymentMethods = this.checkout?.results?.paymentMethods || []
        // 订单详情
      } else if (
        [2, 5].includes(this.nowPageType) ||
        ['XtraDetail'].includes(this.nowCheckoutScene)
      ) {
        paymentMethods = this.payments
      }
      return this.getPpgvAccountInfo(paymentMethods)
    },
    afterPayAccountInfo() {
      let paymentMethods = []
      // 下单
      if (
        [1, 3, 4, 6].includes(this.nowPageType) ||
        ['Xtra'].includes(this.nowCheckoutScene)
      ) {
        paymentMethods = this.checkout?.results?.paymentMethods || []
        // 订单详情
      } else if (
        [2, 5].includes(this.nowPageType) ||
        ['XtraDetail'].includes(this.nowCheckoutScene)
      ) {
        paymentMethods = this.payments
      }
      return this.getAfterPayAccountInfo(paymentMethods)
    },
    signupAccountInfo() {
      let paymentMethods = []
      // 下单
      if (
        [1, 3, 4, 6].includes(this.nowPageType) ||
        ['Xtra'].includes(this.nowCheckoutScene)
      ) {
        paymentMethods = this.checkout?.results?.paymentMethods || []
        // 订单详情
      } else if (
        [2, 5].includes(this.nowPageType) ||
        ['XtraDetail'].includes(this.nowCheckoutScene)
      ) {
        paymentMethods = this.payments
      }
      const signupAccountInfo = {}
      paymentMethods.forEach(item => {
        signupAccountInfo[item.code] = this.getNoTokenCardAccountInfo(item)
      })
      return signupAccountInfo
    },
    isContinueSign() {
      // return this.paypalGaVault.type == '1' && this.ppgvAccountInfo?.signUpEmail
      const signupPayVault = this.signupPayVault['PayPal-GApaypal']
      return this.ppgvAccountInfo?.signUpEmail && !signupPayVault?.useOneTimePay
    },
    hasStoreGoods() {
      let carts = []
      if ([2].includes(this.nowPageType)) {
        carts = this.orderInfo?.oldOrderGoodsList || []
      } else if ([5].includes(this.nowPageType)) {
        carts = this.orderInfo?.orderGoodsList || []
      } else {
        carts = this.checkout?.results?.carts?.carts || []
      }
      //0：自营；1：店铺
      return carts.some(item => item.business_model == 1)
    },
    paypalBnplCurrencySupport(){
      let paypalBnplPayment = null
      const paypalBnplSupports = this.currencySupport?.filter(item => item.payMethod == 'PayPal-bnpl') || []

      if (paypalBnplSupports.length == 0) {
        return paypalBnplPayment
      }
      for (const i in paypalBnplSupports) {
        if (paypalBnplSupports[i].countryCode == this.countryCode && this.countryCode != '') {
          paypalBnplPayment = paypalBnplSupports[i]
          break
        }
      }

      return paypalBnplPayment
    },
    currencyCode() {
      return this?.now_currency || this?.nowCurrency || this.orderInfo?.currency_code || ''
    },
    currentOnlinePayDiscountInfo(){
      const onlinePayDiscountInfo = this?.price?.onlinePayDiscountInfo?.onlinePayDiscountInfo
      const hasOnlinePayDiscount = onlinePayDiscountInfo?.isEnjoyedDiscount == 1 
          && onlinePayDiscountInfo?.discountType != 2
      return {
        originalTotalPrice: onlinePayDiscountInfo?.originalTotalPrice?.amount,
        hasOnlinePayDiscount,
      }
    }
  },

  created() {
    this.getPageType()
  },

  methods: {
    ...mapMutations(['changeRootStatus']),
    isSupportPaypalBnpl(currency) {
      const { currencySupportList = [], defaultCurrency } = this.paypalBnplCurrencySupport || {}
      const isSupport = currencySupportList?.some(item => item == currency)
      return isSupport && defaultCurrency
    },
    initAfterpaySignAbt(paymentMethods, noCardTokenPayAbtInfo, isPrime = false) {
      // ABT：开启token支付
      const paymentTokenSupport = noCardTokenPayAbtInfo?.PaymentUnsignExp?.p?.PaymentSignupShow === 'Show' || false
      // ABT：文案的abt
      const afterpaySavedword = noCardTokenPayAbtInfo?.afterpaySavedword?.p?.afterpaySavedwordStatus === 'A'

      this.afterPayVault.switch = paymentTokenSupport ? true : false
      this.afterPayVault.title = afterpaySavedword ? 'A' : 'B'
      this.afterPayVault.valid = paymentTokenSupport ? true : false

      const paymentInfo = paymentMethods?.find?.(item => item.code === 'afterpay-card') || {}
      const afterpaytokenRemember = paymentInfo?.default_sign_remember === 1

      this.afterPayVault.radioType = (afterpaytokenRemember || isPrime) ? AFTERPAY_CHECK_TYPE.CHECKED : AFTERPAY_CHECK_TYPE.UNCHECKED
      this.afterPayVault.type = (afterpaytokenRemember || isPrime) ? AFTERPAY_CHECK_TYPE.CHECKED : AFTERPAY_CHECK_TYPE.UNCHECKED
      
      // 有签约信息
      const afterPayAccountInfo = this.getAfterPayAccountInfo(paymentMethods)
      if(afterPayAccountInfo?.id){
        // 不展示勾选开关
        this.afterPayVault.valid = false
        this.afterPayVault.radioType = AFTERPAY_CHECK_TYPE.CHECKED
        this.afterPayVault.type = AFTERPAY_CHECK_TYPE.CHECKED
      } else {
        // 无签约信息,展示老文案
        this.afterPayVault.title = ''
      }
    },
    initNoCardToKenSignInfo(paymentInfo, noCardTokenPayAbtInfo) {
      let { code = '', default_sign_remember } = paymentInfo
      // this.signupPayVault[code] = {
      //   valid: true,
      //   radioType: default_sign_remember == 1 ? SIGNUP_CHECK_TYPE.CHECKED : SIGNUP_CHECK_TYPE.UNCHECKED,
      //   type: default_sign_remember == 1 ? SIGNUP_CHECK_TYPE.CHECKED : SIGNUP_CHECK_TYPE.UNCHECKED,
      // }
      const isAutoRenewal = this.$route?.query?.product_activity_type === '1' || this.orderInfo?.vip_order_source == 1
      // ppga增加abt控制
      if (code === 'PayPal-GApaypal') {
        default_sign_remember = default_sign_remember == 1 && noCardTokenPayAbtInfo?.PaymentUnsignExp?.p?.PaypalRememberShow === 'Default' ? 1 : 0
      }
      this.$set(this.signupPayVault, code, {
        valid: true,
        radioType: isAutoRenewal || default_sign_remember == 1 ? SIGNUP_CHECK_TYPE.CHECKED : SIGNUP_CHECK_TYPE.UNCHECKED,
        type: isAutoRenewal || default_sign_remember == 1 ? SIGNUP_CHECK_TYPE.CHECKED : SIGNUP_CHECK_TYPE.UNCHECKED,
        useOneTimePay: false,
      })

      const accountInfo = this.getNoTokenCardAccountInfo(paymentInfo)
      if (accountInfo?.id) {
        this.signupPayVault[code].valid = false
        this.signupPayVault[code].radioType = SIGNUP_CHECK_TYPE.CHECKED
        this.signupPayVault[code].type = SIGNUP_CHECK_TYPE.CHECKED
      }
    },
    /** 获取签约信息 */
    getPpgvAccountInfo(paymentMethods = []) {
      if (!paymentMethods.length) return {}
      const matchPaypalGA = paymentMethods.find(
        item => item.code === 'PayPal-GApaypal'
      )
      return (
        (matchPaypalGA &&
          matchPaypalGA.paymentSignUp &&
          matchPaypalGA.paymentSignUp[0]) ||
        {}
      )
    },
    /** 获取afterpay签约信息 */
    getAfterPayAccountInfo(paymentMethods = []) {
      if (!paymentMethods.length) return {}
      const matchAfterPay = paymentMethods.find(
        item => item.code === 'afterpay-card'
      )
      return (
        (matchAfterPay &&
          matchAfterPay.paymentSignUp &&
          matchAfterPay.paymentSignUp[0]) ||
        {}
      )
    },
    /** 获取非token卡支付签约信息 */
    getNoTokenCardAccountInfo(payment) {
      if (!payment) return {}
      return payment?.paymentSignUp?.[0] || {}
    },
    async getInlineAbt() {
      this.processList.push('PayInlineAbt')
      this.recordPayLineErr('/inline-pp-abt-error', {
        originAbt: this.payInlineAbtInfo
      })

      //如果abt没值  则处理下
      if (this.payInlineAbtInfo && !Object.keys(this.payInlineAbtInfo).length) {
        // eslint-disable-next-line @shein-aidc/abt/abt
        const { PayInline } = await abtservice.getUserAbtResult({
          posKeys: 'PayInline'
        })
        let payInlineInfo = {}
        if (PayInline && PayInline.param) {
          let channel = PayInline.param.split('&')
          channel.forEach(item => {
            let inlineInfoItem = item.split('=')
            if (inlineInfoItem[1]) {
              payInlineInfo[inlineInfoItem[0]] = inlineInfoItem[1].split(',')
            }
          })
        }
        if (payInlineInfo['paypal']) {
          if (!payInlineInfo['paypal'].includes('PayPal-GApaypal')) {
            payInlineInfo['paypal'].push('PayPal-GApaypal')
            this.recordPayLineErr('/inline-pp-abt-error', {
              abtInfo: this.payInlineAbtInfo,
              PayInline
            })
          }
        } else {
          payInlineInfo.paypal = ['PayPal-GApaypal']
          this.recordPayLineErr('/inline-pp-abt-error', {
            abtInfo: this.payInlineAbtInfo,
            PayInline
          })
        }

        Vue.set(this, 'payInlineAbtInfo', {
          ...payInlineInfo,
          paypal: [
            ...(payInlineInfo?.paypal || []),
            ...this.defaultInlineMethods
          ]
        })

        //排查问题记录日志
        this.recordPayLineErr('/inline-pp-abt-record', {
          abtInfo: payInlineInfo,
          PayInline
        })
      } else {
        this.$set(this, 'payInlineAbtInfo', {
          ...this.payInlineAbtInfo,
          paypal: [
            ...(this.payInlineAbtInfo?.paypal || []),
            ...this.defaultInlineMethods
          ]
        })
      }

      //如果上面到这里payInlineAbtInfo不存在或者为空对象，则兜底paypal inline
      if (
        !this.payInlineAbtInfo ||
        !Object.keys(this.payInlineAbtInfo).length
      ) {
        Vue.set(this, 'payInlineAbtInfo', {
          paypal: [...this.defaultInlineMethods]
        })
        this.recordPayLineErr('/inline-pp-abt-empty', {})
      }
    },
    initPayInlineSession: function ({
      paymentMethods = [],
      fromAgain = false,
      cb,
      justReq = false
    }) {
      let abtMethods = []
      let needPayInlinePaymentMethods = []
      this.$set(this, 'payInlineAbtInfo', {
        ...this.payInlineAbtInfo,
        paypal: [
          ...(this.payInlineAbtInfo?.paypal || []),
          ...this.defaultInlineMethods
        ]
      })
      const payInlineAbtInfo = this.payInlineAbtInfo || {} // klarna等分期直连支付方式abt

      Object.keys(payInlineAbtInfo).forEach(key => {
        abtMethods = abtMethods.concat(payInlineAbtInfo[key])
      })
      if (abtMethods.length) {
        paymentMethods.forEach(item => {
          if (abtMethods.indexOf(item.code) > -1 && item.enabled == 1) {
            needPayInlinePaymentMethods.push(item.code)
          }
        })
      }
      //this.needPayInlinePaymentMethods = needPayInlinePaymentMethods
      Vue.set(this, 'needPayInlinePaymentMethods', needPayInlinePaymentMethods)
      this.getPayInlineSession({ fromAgain, cb, justReq })

      //默认是选中签约
      if (
        [2, 5].includes(this.nowPageType) ||
        ['XtraDetail'].includes(this.nowCheckoutScene)
      ) {
        paymentMethods.forEach(item => {
          if (
            item.enabled == 1 &&
            item.code == 'PayPal-GApaypal' &&
            item.paymentSignUp &&
            item.paymentSignUp.length
          ) {
            this.paypalGaVaultBi &&
              this.paypalGaVaultBi('expose_paypal_vaulting')
          }
        })
      }

      //记录日志
      const payments = paymentMethods.map(item => item.code)
      this.recordPayLineErr('/need-payinline-methods', {
        needPayInlinePaymentMethods,
        payments
      })
    },

    getPayInlineSession: function ({ cb, justReq }) {
      if (this.sdkTokenReqing) return
      if (!this.needPayInlinePaymentMethods.length) return
      let amount = 0
      let country = ''
      let orderCurrency = ''

      if (this.nowPageType == 1) {
        amount = this.checkout?.mall_caculate_info?.grandTotalPrice?.amount
        country = this.checkout?.default_address?.value
        orderCurrency = this.now_currency
      } else if (
        [2, 5, 8].includes(this.nowPageType) ||
        ['XtraDetail'].includes(this.nowCheckoutScene)
      ) {
        amount = this.orderInfo?.totalPrice?.amount
        country = this.orderInfo?.shipping_country_code
        orderCurrency = this.orderInfo.currency_code
      } else if (this.nowPageType == 3) {
        amount = this.cardInfo?.sale_price_fromat_price?.amount
        country = this.defaultAddress.value
        orderCurrency = this.nowCurrency

        //礼品卡二次下单页
      } else if (this.nowPageType == 4) {
        amount = this.orderInfo?.currency_total_all
        country = this.orderInfo?.countryCode
        orderCurrency = this.orderInfo.currency_code
        // 会员单独购
      } else if (
        this.nowPageType == 6 ||
        ['Xtra'].includes(this.nowCheckoutScene)
      ) {
        amount = this.primePriceInfo?.paid_total?.amount
        country = this.defaultAddress.value
        orderCurrency = this.nowCurrency
      }
      if (!(amount && country)) {
        !country &&
          this.sendErrorReport('paypal_countryValue_empty', {
            failure_type: 'api',
            client_url: '/api/checkout/channelSession/create',
            description: 'Paypal获取 session 国家信息为空'
          })

        !amount &&
          this.sendErrorReport('paypal_amount_empty', {
            failure_type: 'api',
            client_url: '/api/checkout/channelSession/create',
            description: 'Paypal获取 session 金额信息为空'
          })
        return
      }
      this.countryCode = country
      let data = {
        shipCountry: country,
        orderAmount: '' + amount,
        // businessModel: this.hasStoreGoods ? 1 : 0,
        orderCurrency,
        payMethodList: this.needPayInlinePaymentMethods
      }

      let orderDetailsCacheVal = ''

      //   console.log('createSessionOrderDetails', this.createSessionOrderDetails)
      if (this.createSessionOrderDetails?.length) {
        data.orderDetails = this.createSessionOrderDetails
        orderDetailsCacheVal = data.orderDetails?.map?.(v => `${v.businessModel}:${v.companyId}`).join('_') || ''
      }
      let cacheKey =
        data.shipCountry +
        '_' +
        data.orderAmount +
        '_' +
        data.orderCurrency +
        '_' +
        data.payMethodList.join('_') +
        '_' +
        orderDetailsCacheVal


      this.nowInlineCacheKey = cacheKey

      let paymentCode = ''
      if (
        [1, 3, 4, 6].includes(this.nowPageType) ||
        ['Xtra'].includes(this.nowCheckoutScene)
      ) {
        paymentCode = this.status.cardPay
      } else if (
        [2, 5].includes(this.nowPageType) ||
        ['XtraDetail', 'BuyPrime'].includes(this.nowCheckoutScene)
      ) {
        paymentCode = this.payments?.find?.(item => item.checked)?.code || ''
      }
      // 其它支付方式有在线优惠需要取原价
      if(this.currentOnlinePayDiscountInfo.hasOnlinePayDiscount && paymentCode !== 'PayPal-bnpl'){
        this.totalPriceAmount = this.currentOnlinePayDiscountInfo.originalTotalPrice + ''
      } else {
        this.totalPriceAmount = amount + ''
      }

      // fix 如果已经展示了paypal按钮，重新调用创建session接口时，选中的支付方式依然是ppga，此时不需要提前重置showWidgetForm
      // 否则按钮会闪一下
      if (!(this.paypalBtnShow && paymentCode === 'PayPal-GApaypal') || !(this.paypalBnplBtnShow && paymentCode === 'PayPal-bnpl')) {
        this.showWidgetForm = 1
      }
      
      if (this.payInlineSessionInfoCache[cacheKey]) {
        Vue.set(
          this,
          'payInlineSessionInfo',
          this.payInlineSessionInfoCache[cacheKey]
        )

        if (paymentCode === 'klarna-sliceitdirect') {
          const payInlineCache = this.payInlineSessionInfoCache[cacheKey]?.[paymentCode]
          SILogger.logInfo({
            tag: 'Klarna_sdk_log',
            message: 'klarna use cache session',
            params: {
              scene: 'initCacheSession',
              client_token: payInlineCache?.clientToken || '',
              sessionId: payInlineCache?.sessionId || '',
              cacheKey: cacheKey || ''
            }
          })
        }

        let payInlineChannel = this.judgeNeedPayInlineChannel(paymentCode)
        this.processList.push('channelToken-' + payInlineChannel)
        if (paymentCode !== 'PayPal-Venmo') {
          this.paypalVenmoBtnShow = false
        }
        if (payInlineChannel) {
          this[payInlineChannel] && this[payInlineChannel](paymentCode)
        }
        cb && cb()
      } else {
        if (!justReq) {
          this.sdkTokenReqing = true
        }
        // 订单详情 个人中心转换优化调整
        // if([2].includes(this.nowPageType) || ['XtraDetail'].includes(this.nowCheckoutScene)){
        // 	const selectedPayment = this.payments.filter((item) => {
        // 		if (item.checked) return item
        // 	})[0]
        // 	let paymentCode = selectedPayment && selectedPayment.code || ''
        // 	let payInlineChannel =  this.judgeNeedPayInlineChannel(paymentCode)
        // 	if(payInlineChannel){
        // 		this.loadingStatus(true)
        // 	}
        // }
        schttp({
          method: 'POST',
          url: '/api/checkout/channelSession/create',
          data
        })
          .then(res => {
            let returnStatus = 'sessionapi_return_clientToken'
            if (res.code != 0) {
              returnStatus = 'sessionapi_not_response'
            }
            if (res.code == 0 && !res?.info?.sessions?.length) {
              returnStatus = 'sessionapi_return_failure'
            }

            // 创建session接口返回结果曝光
            daEventCenter?.triggerNotice?.({
              daId: '2-31-6',
              extraData: {
                failure_msg: res?.code || '',
                payment_method: 'PayPal-GApaypal',
                return_status: returnStatus
              }
            })
            if (!res?.info?.sessions?.length) {
              this.recordPayLineErr('post-channel-token', { res, data })
            }

            if (!justReq) {
              this.sdkTokenReqing = false
            }
            let failChannel = []
            if (res.code == 0) {
              let sessions = res?.info?.sessions || []
              if (!sessions.length) {
                data.payMethodList.forEach(key => {
                  Object.keys(this.payInlineAbtInfo).forEach(channel => {
                    if (this.payInlineAbtInfo[channel].includes(key)) {
                      failChannel.push(channel)
                    }
                  })
                })

                this.recordPayLineErr(
                  '/checkout/postCreateChannelSession/' + failChannel.join(','),
                  res
                )
                return
              }
              let payInlineSessionInfo = {}
              sessions.forEach(item => {
                payInlineSessionInfo[item.payMethod] = item
              })
              this.$set(this, 'payInlineSessionInfo', payInlineSessionInfo)

              this.payInlineSessionInfoCache[cacheKey] = payInlineSessionInfo
              this.$set(this, 'payInlineSessionInfoCache', this.payInlineSessionInfoCache)

              if (sessions?.find?.(v => v.payMethod === 'klarna-sliceitdirect')) {
                const payInlineInfo = payInlineSessionInfo?.['klarna-sliceitdirect']
                SILogger.logInfo({
                  tag: 'Klarna_sdk_log',
                  message: 'klarna use new session',
                  params: {
                    scene: 'initNewSession',
                    client_token: payInlineInfo?.clientToken || '',
                    sessionId: payInlineInfo?.sessionId || '',
                    cacheKey: cacheKey || ''
                  }
                })
              }

              if (justReq) {
                return
              }

              // 如果选中方式需要直连则需要初始化sdk
              let paymentCode = ''
              if (
                [1, 3, 4, 6].includes(this.nowPageType) ||
                ['Xtra'].includes(this.nowCheckoutScene)
              ) {
                paymentCode = this.status.cardPay
              } else if (
                [2, 5].includes(this.nowPageType) ||
                ['XtraDetail', 'BuyPrime'].includes(this.nowCheckoutScene)
              ) {
                const selectedPayment = this.payments.filter(item => {
                  if (item.checked) return item
                })
                paymentCode =
                  (selectedPayment &&
                    selectedPayment[0] &&
                    selectedPayment[0].code) ||
                  ''
              }

              let payInlineChannel = this.judgeNeedPayInlineChannel(paymentCode)
              this.processList.push('channelToken-' + payInlineChannel)

              if (payInlineChannel) {
                payInlineChannel !== 'paypal' && this.loadPayPalMessage()
                this[payInlineChannel] && this[payInlineChannel](paymentCode)
                cb && cb()
              } else {
                this.loadingStatus(false)
                this.loadPayPalMessage()
                this.paypalGaBtn = false
              }

              //对比传参的支付code，筛选出没返回token的支付code的渠道
              data.payMethodList.forEach(key => {
                if (!Object.keys(payInlineSessionInfo).includes(key)) {
                  Object.keys(this.payInlineAbtInfo).forEach(channel => {
                    if (this.payInlineAbtInfo[channel].includes(key)) {
                      failChannel.push(channel)
                    }
                  })
                }
              })

              //let failChannel = Object.keys(this.payInlineAbtInfo).filter(key=>this.payInlineAbtInfo[key].every(m=>!payInlineSessionInfo[m]))

              if (failChannel.length) {
                this.recordPayLineErr(
                  '/checkout/postCreateChannelSession/' + failChannel.join(','),
                  res
                )
              }
            } else {
              this.paypalGaBtn = false
              this.paypalMessageShow = false
              this.recordPayLineErr('/checkout/postCreateChannelSession', res)

              this.sendErrorReport('create_session_res_error', {
                failure_type: 'sdk',
                client_url: '/api/checkout/channelSession/create',
                description: '创建渠道Session返回状态异常',
                status_code: res?.code || ''
              })
            }
          })
          .catch(err => {
            //如果最新token更新失败，则paypal-ga走兜底
            this.paypalGaBtn = false
            this.paypalMessageShow = false
            this.sdkTokenReqing = false
            this.loadingStatus(false)
            // 创建session接口返回结果曝光
            daEventCenter?.triggerNotice?.({
              daId: '2-31-6',
              extraData: {
                failure_msg: err?.message || '',
                payment_method: 'PayPal-GApaypal',
                return_status: 'sessionapi_not_response'
              }
            })

            this.sendErrorReport('create_session_catch_error', {
              failure_type: 'sdk',
              client_url: '/api/checkout/channelSession/create',
              description: `创建渠道Session失败，${err?.message || ''}`,
            })
            this.recordPayLineErr('/channel-token-error', err)
          })
      }
    },

    getPageType() {
      if (typeof window == 'undefined') {
        return
      }

      const pathname = location.pathname
      const isCheckout =
        pathname.indexOf('/checkout') > -1 && pathname.indexOf('again') == -1
      const isGiftCard = pathname.indexOf('/giftcard') > -1
      const isBuyPrime = pathname.indexOf('/buyprime') > -1
      if (isCheckout && !isGiftCard && !isBuyPrime) {
        // 普通一次下单
        this.nowPageType = 1
      } else if (!isCheckout && !isGiftCard && !isBuyPrime) {
        // 普通订单详情
        this.nowPageType = 2
      } else if (isCheckout && isGiftCard) {
        // 礼品卡一次下单
        this.nowPageType = 3
      } else if (!isCheckout && isGiftCard) {
        // 礼品卡二次下单
        this.nowPageType = 4
      } else if (isBuyPrime) {
        // 单独购
        this.nowPageType = 6
      }
      if (location.pathname.indexOf('/orders/list') > -1) {
        // 普通订单列表
        this.nowPageType = 5
      }
      // if (this.nowCheckoutScene === 'Xtra') { // 超省卡单独购
      // 	this.nowPageType = 7
      // }
      // if (this.nowCheckoutScene === 'XtraDetail') {  // 超省卡订单详情
      // 	this.nowPageType = 8
      // }
    },
    setNowCheckoutScene(scene = '') {
      if (scene === 'XtraDetail') {
        this.nowPageType = 8
      }
      if (scene === 'Xtra') {
        this.nowPageType = 7
      }

      this.nowCheckoutScene = scene || ''
    },
    loadingStatus(isShow, delay) {
      //如果是在下单页
      if ([1].includes(this.nowPageType)) {
        if (delay) {
          if (isShow && this.loadingParam.timer) return
          isShow ? this.showPageLoading() : this.hidePageLoading()
        } else {
          this.loadingShow = isShow
        }
      } else if (
        [3, 4, 6].includes(this.nowPageType) ||
        ['Xtra'].includes(this.nowCheckoutScene)
      ) {
        this.loadingShow = isShow
      } else if (
        [2, 5].includes(this.nowPageType) ||
        ['XtraDetail'].includes(this.nowCheckoutScene)
      ) {
        this.changeRootStatus({
          loading: isShow
        })
      }
    },
    paypalFn() {
      //在订单详情页时
      if (
        (this.nowPageType == 2 ||
          ['XtraDetail'].includes(this.nowCheckoutScene)) &&
        this.payments
      ) {
        const selectedPayment = this.payments.filter(item => {
          if (item.checked) return item
        })[0]
        if (selectedPayment?.code) {
          this.paypal(selectedPayment.code)
        }
      }
    },

    initKlarnaPayInlineSDK: function () {
      const payInlineAbtInfo = this.payInlineAbtInfo || {} // klarna等分期直连支付方式abt
      const channelKeys = Object.keys(payInlineAbtInfo)
      if (channelKeys.includes('klarna')) {
        asyncLoadFile({
          label: 'script',
          attrs: { src: 'https://x.klarnacdn.net/kp/lib/v1/api.js' }
        })
          .then(() => {
            this.loadingStatus(false)
            console.log('load success')
          })
          .catch(() => {
            this.loadingStatus(false)
          })
      }
    },

    judgeNeedPayInlineChannel: function (paymentMethod) {
      let payInlineChannel = ''
      const payInlineAbtInfo = this.payInlineAbtInfo || {} // klarna等分期直连支付方式abt
      if (this.needPayInlinePaymentMethods.includes(paymentMethod)) {
        for (let key in payInlineAbtInfo) {
          if (
            payInlineAbtInfo[key].includes(paymentMethod) &&
            channelList.includes(key)
          ) {
            payInlineChannel = key
          }
        }
      }

      this.chosenChannel = payInlineChannel
      return payInlineChannel
    },

    async loadPaypalScript(payment_method) {
      if (this.paypalSdkLoadSuccess[payment_method])
        return this.loadingStatus(false, true)
      const payInlineAbtInfo = this.payInlineAbtInfo || {} // klarna等分期直连支付方式abt
      const channelKeys = Object.keys(payInlineAbtInfo)
      //paypal-ga渠道sdk
      if (channelKeys.includes('paypal')) {
        //const attrs = { async: true }
        let paypalSdks = paypalChannelSdks[payment_method] || []
        let loadCount = 0
        const arr = []
        paypalSdks.forEach(src => {
          arr.push(
            asyncLoadFile({
              label: 'script',
              attrs: { src }
            }).then(() => {
              loadCount++
            })
          )
        })

        this.loadingStatus(true, true)
        await Promise.all(arr)
          .then(() => {
            if (loadCount == paypalSdks.length) {
              // PayPal-GApaypal 和 PayPal-bnpl 共用一个sdk
              if(['PayPal-GApaypal', 'PayPal-bnpl'].includes(payment_method)){
                this.paypalSdkLoadSuccess['PayPal-GApaypal'] = true
                this.paypalSdkLoadSuccess['PayPal-bnpl'] = true
              } else {
                this.paypalSdkLoadSuccess[payment_method] = true
              }

            }
          })
          .catch(err => {
            console.log('load paypal sdk err', err)
            this.recordPayLineErr('/load-paypal-sdk-err', err)
            this.paypalSdkLoadSuccess[payment_method] = false
          })
      }
    },

    //根据渠道调用: this[payInlineChannel](this.status.cardPay)
    async paypal(payment_method) {
      const info = this.payInlineSessionInfo[payment_method]
      const clientToken = info && info.clientToken
      let profileId = (info && info.profileId) || ''
      const _this = this

      await this.loadPaypalScript(payment_method)

      //console.log('paypal ga client token: ', clientToken)
      if (
        !clientToken ||
        !window.braintree ||
        !this.paypalSdkLoadSuccess[payment_method]
      ) {
        this.showWidgetForm = 1
        this.loadingStatus(false, true)
        if (!clientToken) {
          console.log('Missing paypal client token', clientToken)
          this.recordPayLineErr('inline/paypal/initSdk', {
            msg: '缺少clientToken',
            payment_method,
            clientToken
          })

          this.sendErrorReport('paypal_client_token_again_error', {
            failure_type: 'api',
            client_url: '/api/checkout/channelSession/create',
            description: 'Paypal 缺少clientToken',
          })
        }

        if (!this.paypalSdkLoadSuccess[payment_method]) {
          this.recordPayLineErr('inline/paypal/initSdk', {
            msg: 'sdk加载失败',
            payment_method
          })

          this.sendErrorReport('paypal_init_sdk_error', {
            failure_type: 'sdk',
            client_url: '/third/sdk/error',
            description: 'Paypal获取 session script 失败'
          })
        }

        return
      }

      if (payment_method == 'PayPal-Venmo') {
        if (!profileId) {
          profileId = IS_RW ? '3535562754895009667' : '3535110066272371717'
        }
        this.initPayPalVenmo(clientToken, payment_method, profileId)
        return
      }
      this.paypalVenmoBtnShow = false

      // 以下为PayPal-GApaypal处理逻辑
      //目前的paypal按钮对应的clientToken参数不变的话则不重新生成按钮
      // if (
      //   this.nowPaypalCacheKey &&
      //   this.nowPaypalCacheKey == this.nowInlineCacheKey
      // ) {
      //   this.showWidgetForm = 2
      //   this.loadingStatus(false, true)
      //   return
      // }

      if (this.paypalSdkLauching) return
      this.loadingStatus(true, true)
      this.paypalSdkLauching = true

      paypalSdkTimeout = setTimeout(() => {
        if (!this.paypalGaBtn) {
          this.loadingStatus(false, true)
          this.recordPayLineErr('inline/paypal/initSdk', {
            msg: 'sdk响应时间过长'
          })
          console.log('paypal sdk timeout')
        }
      }, 20 * 1000)

      this.processList.push('paypalClientCreate: ' + clientToken)

      // Create a client.
      await braintree.client
        .create({
          authorization: clientToken
        })
        .then(function (clientInstance) {
          //device data
          braintree.dataCollector
            .create({
              client: clientInstance
            })
            .then(function (dataCollectorInstance) {
              _this.paypalDeviceId = dataCollectorInstance.deviceData
              if (!_this.paypalDeviceId) {
                _this.recordPayLineErr('inline/paypal/initSdk', {
                  msg: payment_method + ' 返回risk data为空',
                  dataCollectorInstance
                })
              }
            })
            .catch(err => {
              console.log('paypal deviceData err', err)
              _this.recordPayLineErr('inline/paypal/initSdk', {
                msg: payment_method + ' 获取risk data失败',
                err
              })
            })

          //初始化组件
          console.log('Initialize components')
          // Create a PayPal Checkout component.
          return braintree.paypalCheckout.create({
            client: clientInstance
          })
        })
        .then(function (paypalCheckoutInstance) {
          console.log('Load the PayPal JS SDK')
          // Load the PayPal JS SDK (see Load the PayPal JS SDK section)

          let currency = ''
          if (
            [2, 5].includes(_this.nowPageType) ||
            ['XtraDetail'].includes(_this.nowCheckoutScene)
          ) {
            currency = _this.orderInfo.currency_code
          } else if (_this.nowPageType == 1) {
            currency = _this.now_currency
          } else if (
            [3, 4, 6].includes(_this.nowPageType) ||
            ['Xtra'].includes(_this.nowCheckoutScene)
          ) {
            currency = _this.nowCurrency
          }

          const sdkConfig = {
            currency: currency || 'USD',
            intent: 'capture',
            components: 'buttons,messages'
          }
          if (payment_method == 'PayPal-bnpl') {
            sdkConfig['enable-funding'] = 'paylater'
            const isSandBox = ['debug', 'localhost'].includes(gbCommonInfo?.NODE_SERVER_ENV)
            if (isSandBox) {
              sdkConfig['buyer-country'] = _this.orderData?.countryCode || _this.countryCode ||
              _this.checkout?.default_address?.value ||
              _this.defaultAddress?.value
            }
            sdkConfig.dataAttributes = {
              amount: _this.totalPriceAmount ? Number(_this.totalPriceAmount).toFixed(2) : '',
            }
          }

          return paypalCheckoutInstance
            .loadPayPalSDK(sdkConfig)
            .then(data => {
              daEventCenter?.triggerNotice?.({
                daId: '2-31-5',
                extraData: {
                  payment_method,
                  return_status: 'sdkjsapi_return_success'
                }
              })
              return data
            })
            .catch(err => {
              daEventCenter?.triggerNotice?.({
                daId: '2-31-5',
                extraData: {
                  payment_method,
                  return_status: 'sdkjsapi_return_failure'
                }
              })
              _this.ppBraintreeErrorReport({
                apiScene: 'loadPayPalSDK',
                error: err,
                payment_method
              })
              return err
            })
        })
        .then(function (paypalCheckoutInstance) {
          return _this.renderPaypalBtn(paypalCheckoutInstance, payment_method)
        })
        .catch(function (err) {
          _this.paypalGaBtn = false
          _this.paypalSdkLauching = false
          _this.paypalMessageShow = false
          _this.loadingStatus(false, true)
          console.log('paypal ga sdk error: ', err)
          _this.recordPayLineErr('inline/paypal/initSdk', {
            msg: '初始化失败',
            err
          })
          clearTimeout(paypalSdkTimeout)
          // Handle component creation error

          _this.sendErrorReport('paypal_init_sdk_error', {
            failure_type: 'sdk',
            client_url: '/third/sdk/error',
            description: 'Paypal session 初始化失败'
          })

          return null
        })
    },

    async loadPayPalMessage(payment_method = 'PayPal-bnpl') {
      const info = this.payInlineSessionInfo[payment_method]
      const clientToken = info && info.clientToken
      const currency = this?.now_currency || this?.nowCurrency || this.orderInfo?.currency_code

      let _this = this
      await this.loadPaypalScript(payment_method)

      if (
        !clientToken ||
        !window.braintree ||
        !this.paypalSdkLoadSuccess[payment_method] ||
        !this.isSupportPaypalBnpl(this.currencyCode)
      ) {
        this.loadingStatus(false, true)
        this.paypalMessageShow = false
        console.log('Missing paypal client token')
        return
      }

      await braintree.client
        .create({
          authorization: clientToken
        })
        .then(function (clientInstance) {
          return braintree.paypalCheckout.create({
            client: clientInstance
          })
        })
        .then(function (paypalCheckoutInstance) {
          return paypalCheckoutInstance
            .loadPayPalSDK({
              currency: currency || 'USD',
              intent: 'capture',
              components: 'messages'
            })
            .then(data => {
              _this.loadingStatus(false, true)
              _this.paypalMessageShow = true
              console.log('loadPayPalMessage success')
              return data
            })
        }).catch(err => {
          _this.loadingStatus(false, true)
          _this.paypalMessageShow = false
          console.log('loadPayPalMessage error', err)
          return err
        })
    },

    setBtnStyleData(id) {
      const style = {
        layout: 'vertical',
        color: IS_RW ? 'blue' : 'black',
        shape: 'rect',
        label: 'paypal'
      }

      if (this.nowPageType == 1) {
        style.layout = 'horizontal'
        style.height = 30
        // 下单页pp按钮高度处理
        const originBtn = document.querySelector('.j-checkout-btn')
        const ppBtn = document.querySelector(`.checkout-summary-footer #${id}`)

        if (originBtn) {
          const computedStyle = window.getComputedStyle(originBtn)
          try {
            style.height = parseFloat(computedStyle.height)
            ppBtn.style.height = computedStyle.height
          } catch (e) {
            style.height = originBtn.clientHeight
          }
        } else if (ppBtn) {
          const computedStyle = window.getComputedStyle(ppBtn)
          style.height = parseFloat(computedStyle.height)
        }
      }
      if (
        this.nowPageType == 2 ||
        ['XtraDetail'].includes(this.nowCheckoutScene)
      ) {
        style.layout = 'horizontal'
        style.height = 25
      }
      // fix 限制最大高度
      // https://developer.paypal.com/sdk/js/reference/#style
      if (style.height > 55) {
        style.height = 50
      }

      return style
    },

    getRenderButtons(payment_method) {
      // 签约按钮
      let renderButtons = []
      if (payment_method === 'PayPal-GApaypal' && document.getElementById('paypal-vault-button')) {
        renderButtons.push({
          type: 1,
          id: `#paypal-vault-button`
        })
      }
      if (payment_method === 'PayPal-bnpl' && document.getElementById('paypal-bnpl-button')) {
        renderButtons.push({
          type: 1,
          id: `#paypal-bnpl-button`
        })
      }
      if (payment_method === 'PayPal-GApaypal' && document.getElementById('drawer-paypal-vault-button')) {
        renderButtons.push({
          type: 1,
          id: `#drawer-paypal-vault-button`
        })
      }
      if (payment_method === 'PayPal-bnpl' && document.getElementById('drawer-paypal-bnpl-button')) {
        renderButtons.push({
          type: 1,
          id: `#drawer-paypal-bnpl-button`
        })
      }
      if (payment_method === 'PayPal-GApaypal' && document.getElementById('paypal-vault-detail-button')) {
        renderButtons.push({
          type: 2,
          id: '#paypal-vault-detail-button'
        })
      }
      if (payment_method === 'PayPal-bnpl' && document.getElementById('paypal-bnpl-detail-button')) {
        renderButtons.push({
          type: 2,
          id: '#paypal-bnpl-detail-button'
        })
      }
      if (payment_method === 'PayPal-GApaypal' && document.getElementById('together-buy-paypal-button')) {
        renderButtons.push({
          type: 1,
          id: '#together-buy-paypal-button'
        })
      }
      if (payment_method === 'PayPal-bnpl' && document.getElementById('together-buy-paypal-bnpl-button')) {
        renderButtons.push({
          type: 1,
          id: '#together-buy-paypal-bnpl-button'
        })
      }
      return renderButtons
    },

    getDataScene(id) {
      let dataScene = ''
      if (document.getElementById(id)) {
        dataScene =
          document
            .getElementById(id)
            ?.getAttribute('data-scene') || ''
      }
      return dataScene
    },

    renderPaypalBtn(paypalCheckoutInstance, payment_method) {
      // if( this.paypalGaBtn ) {
      // 	this.showWidgetForm = 2
      // 	return
      // }
      const _this = this

      const renderButtons = this.getRenderButtons(payment_method)

      // console.log('rendering paypal btn', style)
      this.processList.push('paypalBtnRender')

      //可能有其它接口会取消loading，这里初始化时loading
      this.loadingStatus(true, true)


      // The PayPal button will be rendered in an html element with the ID
      // `paypal-vault-button`. This function will be called when the PayPal button
      // is set up and ready to be used
      return Promise.all(
        renderButtons.map(buttonId => {
          const dataScene = _this.getDataScene(buttonId?.id?.replace(/#/g, ''))
          const style = _this.setBtnStyleData(buttonId?.id?.replace(/#/g, ''))
          const fundingSource = payment_method === 'PayPal-bnpl' ? paypal.FUNDING.PAYLATER : paypal.FUNDING.PAYPAL
          return paypal
            .Buttons({
              // fundingSource: paypal.FUNDING.PAYPAL,
              fundingSource,
              style:
                buttonId.type == 1 && dataScene == 'order'
                  ? { ...style, height: 40 }
                  : style,
              createOrder: function () {
                const order = _this.orderData
                const shippingAddressOverride = {
                  recipientName: `${order.shipping_lastname} ${order.shipping_firstname}`,
                  line1: order.shipping_address_1,
                  line2: order.shipping_address_2,
                  city: order.shipping_city || order.shipping_province,
                  countryCode:
                    order.countryCode ||
                    _this.checkout?.default_address?.value ||
                    _this.defaultAddress?.value,
                  postalCode: order.shipping_postcode,
                  state: order.shipping_province,
                  phone: order.shipping_telephone
                }
                console.log('klarna city: ', shippingAddressOverride.city)
                let amount = order.totalPrice?.amount
                if ([3, 4].includes(_this.nowPageType)) {
                  amount = order.currency_total_all
                }

                const config = {
                  flow: 'checkout', // Required
                  amount, // Required
                  currency: order.currency_code, // Required, must match the currency passed in with loadPayPalSDK
                  intent: 'capture',
                  enableShippingAddress: true,
                  shippingAddressEditable: false,
                  shippingAddressOverride
                }

                // jira.FR-10022
                // wiki.pageId=882028333
                // 普通订单下单/详情/列表/付费会员单独购/超省卡单独购/超省卡二次下单ppga不上送地址
                if (
                  [1, 2, 3, 4, 5, 6].includes(_this.nowPageType) ||
                  ['Xtra', 'XtraDetail'].includes(_this.nowCheckoutScene)
                ) {
                  config.enableShippingAddress = false
                  delete config.shippingAddressEditable
                  delete config.shippingAddressOverride
                }

                //1为签约
                if (_this.signupPayVault?.[payment_method]?.type == '1' &&  payment_method !== 'PayPal-bnpl') {
                  config.requestBillingAgreement = !_this.signupPayVault?.[payment_method]?.useOneTimePay
                }
                // console.log('paypal config: ', config)

                // return paypalCheckoutInstance.createPayment(config)
                return paypalCheckoutInstance
                  .createPayment(config)
                  .then(data => {
                    // 曝光paypal弹窗
                    daEventCenter?.triggerNotice?.({
                      daId: '2-31-3',
                      extraData: {
                        payment_method: 'PayPal-GApaypal'
                      }
                    })
                    return data
                  })
                  .catch(err => {
                    _this.ppBraintreeErrorReport({
                      apiScene: 'createPayment',
                      error: err,
                      payment_method: 'PayPal-GApaypal'
                    })
                  })
              },
              onClick: async function (data, actions) {
                console.log('paypal btn click: ', data, actions)
                let result = {}
                if (
                  [1, 3, 4, 6].includes(_this.nowPageType) ||
                  ['Xtra'].includes(_this.nowCheckoutScene)
                ) {
                  result = await _this.createOrder()
                } else if (
                  [2, 5].includes(_this.nowPageType) ||
                  ['XtraDetail'].includes(_this.nowCheckoutScene)
                ) {
                  result = await _this.handlePaynow()
                }

                let res = (result && result.res) || {}

                _this.loadingStatus(false)

                if (
                  res &&
                  res.code == 0 &&
                  res.info &&
                  res.info.order &&
                  !res.info.order.isPaid
                ) {
                  Vue.set(_this, 'orderData', res.info.order || res.info)
                  billno = res.info.order?.billno || res.info.billno
                  console.log('paypal btn click: resolve')
                  return actions.resolve()
                } else {
                  console.log('paypal btn click: reject')

                  // _this.sendErrorReport('paypal_pay_code_empty', {
                  //   failure_type: 'api',
                  //   client_url: '/web/error',
                  //   description: 'Paypal 点击支付失败'
                  // })
                  _this.sendErrorReport(!result || !result?.res ? 'paypal_click_btn_co_error' : 'paypal_click_btn_res_error', {
                    failure_type: 'api',
                    client_url: '/third/sdk/error',
                    description: `Paypal 点击按钮创单失败, ${res?.code}: ${res?.msg}`,
                  })

                  return actions.reject()
                }
              },

              onApprove: function (data) {
                return paypalCheckoutInstance.tokenizePayment(
                  data,
                  function (err, payload) {
                    // Submit `payload.nonce` to your server
                    Vue.set(_this, 'paypalGaPayLoad', payload)

                    let order = null
                    let pciToken = ''

                    _this.loadingStatus(true)

                    //下单页
                    if (
                      [1, 3, 4, 6].includes(_this.nowPageType) ||
                      ['Xtra'].includes(_this.nowCheckoutScene)
                    ) {
                      order = _this.orderData
                      pciToken = order.jump_pci_token
                      let type = ''
                      if (_this.nowPageType == 3 || _this.nowPageType == 4)
                        type = 'giftcard'
                      if (_this.nowPageType == 6) type = 'buyprime'
                      if (_this.nowCheckoutScene == 'Xtra') type = 'xtra'
                      GB_handlePay(order, {}, _this, pciToken, type)

                      //订单详情页
                    } else if (
                      [2, 5].includes(_this.nowPageType) ||
                      ['XtraDetail'].includes(_this.nowCheckoutScene)
                    ) {
                      order = _this.orderData
                      const paymentCb =
                        _this.paymentCbs(order)[order.payment_method].cb
                      paymentCb(null, () => {
                        _this.loadingStatus(false)
                      })
                    }

                    let nonceReturnStatus = 'nonceapi_return_success'

                    if (err || !payload || !payload.nonce) {
                      if (!err && !payload?.nonce) {
                        nonceReturnStatus = 'nonceapi_return_failure'
                      }
                      if (err) {
                        nonceReturnStatus = 'nonceapi_not_response'
                        _this.ppBraintreeErrorReport({
                          apiScene: 'tokenizePayment',
                          error: err,
                          payment_method: 'PayPal-GApaypal'
                        })
                      }
                      _this.recordPayLineErr('inline/paypal/initSdk', {
                        msg: 'payload支付信息错误',
                        billno,
                        payload,
                        err,
                        nonceReturnStatus
                      })

                      _this.sendErrorReport('paypal_get_nonce_error', {
                        failure_type: 'api',
                        client_url: '/third/sdk/error',
                        description: 'Paypal 获取SDK对应nonce失败, ' + nonceReturnStatus + ': ' + String(err?.message || err?.stack || '').substring(0, 100)
                      })
                    }
                    // 曝光请求渠道SDK获取nonce值结果
                    daEventCenter?.triggerNotice?.({
                      daId: '2-31-4',
                      extraData: {
                        payment_method: 'PayPal-GApaypal',
                        return_status: nonceReturnStatus
                      }
                    })
                  }
                )
              },

              onCancel: function (data) {
                _this.recordPayLineErr('inline/paypal/initSdk', {
                  msg: '支付取消',
                  billno
                })
                // 取消/关闭弹窗点击
                daEventCenter?.triggerNotice?.({
                  daId: '2-31-2',
                  extraData: {
                    payment_method: 'PayPal-GApaypal'
                  }
                })
                _this.sendErrorReport('paypal_render_on_cancel', {
                  failure_type: 'api',
                  client_url: '/third/sdk/error',
                  description: `Paypal 支付取消: ${billno}`,
                })
                console.log(
                  'PayPal payment canceled',
                  JSON.stringify(data, 0, 2)
                )
                if (billno && _this.nowPageType == 1) {
                  window.location.href =
                    host + langPath + '/user/orders/detail/' + billno
                } else if (
                  [2, 4, 5].includes(_this.nowPageType) ||
                  ['XtraDetail'].includes(_this.nowCheckoutScene)
                ) {
                  _this.loadingStatus(false)
                } else if (_this.nowPageType == 3) {
                  window.location.href = `${langPath}/giftcard/checkout/again/${billno}`
                } else if (
                  ['Xtra', 'BuyPrime'].includes(_this.nowCheckoutScene)
                ) {
                  _this.$routerReplace(
                    `${langPath}/user/orders/virtualDetail/${billno}`
                  )
                }
              },

              onError: function (err) {
                // _this.recordPayLineErr('inline/paypal/initSdk', { msg: '支付信息错误', billno, err })
                // console.error('PayPal error', err)
                _this.recordPayLineErr('inline/paypal/initSdk', {
                  msg: '支付信息错误',
                  billno,
                  err: String(err?.message || err?.stack || '').substring(
                    0,
                    600
                  )
                })
                _this.sendErrorReport('paypal_render_on_error', {
                  failure_type: 'api',
                  client_url: '/third/sdk/error',
                  description: `Paypal 支付信息错误: billno:${billno || ''}, ${String(err?.message || err?.stack || '').substring(0, 100)}`,
                })
                // 弹窗异常埋点
                daEventCenter?.triggerNotice?.({
                  daId: '2-31-1',
                  extraData: {
                    payment_method: 'PayPal-GApaypal'
                  }
                })

                if(!billno) return

                if (billno && _this.nowPageType == 1) {
                  window.location.href =
                    host + langPath + '/user/orders/detail/' + billno
                } else if (
                  [2, 4, 5].includes(_this.nowPageType) ||
                  ['XtraDetail'].includes(_this.nowCheckoutScene)
                ) {
                  _this.loadingStatus(false)
                } else if (_this.nowPageType == 3) {
                  window.location.href = `${langPath}/giftcard/checkout/again/${billno}`
                } else if (
                  ['Xtra', 'BuyPrime'].includes(_this.nowCheckoutScene)
                ) {
                  _this.$routerReplace(
                    `${langPath}/user/orders/virtualDetail/${billno}`
                  )
                }
              }
            })
            .render(buttonId.id)
        })
      )
        .then(() => {
          _this.paypalSdkLauching = false
          _this.loadingStatus(false, true)
          _this.paypalGaBtn = true
          _this.paypalMessageShow = true
          _this.nowPaypalCacheKey = _this.nowInlineCacheKey
          _this.showWidgetForm = 2
          console.log('render paypal btn success')
          _this.processList.push('paypalBtnRender-success')
          clearTimeout(paypalSdkTimeout)
        })
        .catch(err => {
          _this.paypalGaBtn = false
          _this.paypalSdkLauching = false
          this.paypalMessageShow = false
          _this.loadingStatus(false, true)
          console.log('render paypal btn error', err)
          _this.recordPayLineErr('inline/paypal/initSdk', {
            msg: '按钮渲染失败',
            err
          })
          _this.sendErrorReport('paypal_render_btn_catch_error', {
            failure_type: 'api',
            client_url: '/third/sdk/error',
            description: `Paypal 按钮渲染失败: ${String(err?.message || err?.stack || '').substring(0, 100)}`,
          })
          clearTimeout(paypalSdkTimeout)
        })
    },
    showPaypalGaType({ isPayListDialog = false } = {}) {
      // this.paypalGaVault.tipShow = true
      // this.paypalGaVault.radioType = this.paypalGaVault.type
      this.paypalGaVault.isPayListDialog = isPayListDialog
      this.paypalGaVaultBi &&
        this.paypalGaVaultBi('click_paypalvaulting_choose')
      // paypal签约弹窗曝光
      daEventCenter?.triggerNotice?.({
        daId: '2-31-7',
        extraData: {
          payment_method: 'PayPal-GApaypal'
        }
      })
    },
    confirmPPGVType(isFromPannel, paymentCode) {
      const isAutoRenewal = this.$route?.query?.product_activity_type === '1'
      if(isAutoRenewal && !isFromPannel) {
        $toast(
          this.language?.SHEIN_KEY_PWA_31535
        )
        return false
      }
      // this.paypalGaVault.type = this.paypalGaVault.radioType
      // this.paypalGaVault.tipShow = false
      // if( this.paypalGaVault.type == '1' ){
      // 	this.paypalGaVaultBi && this.paypalGaVaultBi('expose_paypal_vaulting')
      // }
      if (paymentCode === 'PayPal-GApaypal') {
        let type = ''
        const extraData = {}
        const paypalGaVault = this.signupPayVault['PayPal-GApaypal']
        if (!paypalGaVault?.useOneTimePay && this.ppgvAccountInfo?.signUpEmail) {
          type = 'vaulting'
        } else if (
          paypalGaVault?.type == '1' &&
          !this.ppgvAccountInfo?.signUpEmail
        ) {
          type = 'payandsave'
        } else if (
          paypalGaVault?.type != '1' &&
          this.ppgvAccountInfo?.signUpEmail
        ) {
          type = 'vaultingtoGA'
        } else if (
          paypalGaVault?.type != '1' &&
          !this.ppgvAccountInfo?.signUpEmail
        ) {
          type = 'novaultingtoGA'
        }
        extraData.type = type
        this.paypalGaVaultBi &&
          this.paypalGaVaultBi('click_popup_vaulting_changeconfirm', extraData)
      }
      return true
    },
    
    confirmAfterPayType() {
      const isAutoRenewal = this.$route?.query?.product_activity_type === '1'
      if(isAutoRenewal) {
        return $toast(
          this.language?.SHEIN_KEY_PWA_31535
        )
      }
      this.afterPayVault.type = this.afterPayVault.radioType
    },
    confirmSignupPayType(paymentCode) {
      if (!this.confirmPPGVType(true, paymentCode)) {
        return
      }
      const signupPayVault = this.signupPayVault[paymentCode]
      if (signupPayVault) {
        signupPayVault.type = signupPayVault.radioType
      }
      this.signupPayVault[paymentCode] = signupPayVault
      this.$set(this.signupPayVault, paymentCode, signupPayVault)
    },

    confirmSignupOneTimePay({ paymentCode, selected, isClose = false }) {
      if (!this.confirmPPGVType(isClose || !selected, paymentCode)) {
        return
      }
      this.signupDrawerConfig.show = false
      const signupPayVault = this.signupPayVault[paymentCode]
      if (signupPayVault) {
        signupPayVault.useOneTimePay = selected
      }
      this.$set(this.signupPayVault, paymentCode, signupPayVault)
    },

    recordPayLineErr(channelPath = '', err, extraInfo = {}) {
      const { payment_method = '', billno = '' } = this.orderData || {}
      apiReport.report({
        apiPath: channelPath,
        siteuid: gbCommonInfo.SiteUID,
        billno,
        nowPageType: this.nowPageType,
        nowCheckoutScene: this.nowCheckoutScene,
        payment_method,
        err: typeof err === 'object' ? JSON.stringify(err || {}) : err,
        ...extraInfo
      })
    },
    ppBraintreeErrorReport({ apiScene = '', error = {}, payment_method = '' }) {
      const message = `scene: ${apiScene}, error: ${error?.message || ''}`
      const originError =
        error?.name == 'BraintreeError'
          ? JSON.stringify(error).substring(0, 600)
          : ''
      this.recordPayLineErr('paypal/braintree-error', message, {
        originError,
        payment_method
      })
    },
    initPayPalVenmo(
      clientToken = '',
      payment_method = 'PayPal-Venmo',
      profileId = ''
    ) {
      // Create a client.
      window.braintree.client
        .create({
          authorization: clientToken
        })
        .then(function (clientInstance) {
          // You may need to polyfill Promise
          // if used on older browsers that
          // do not natively support it.
          return Promise.all([
            window.braintree.dataCollector.create({
              client: clientInstance,
              paypal: true
            }),
            window.braintree.venmo.create({
              client: clientInstance,
              allowDesktop: true,
              profileId: profileId,
              paymentMethodUsage: 'single_use' // available in v3.77.0+
              // Add allowNewBrowserTab: false if your checkout page does not support
              // relaunching in a new tab when returning from the Venmo app. This can
              // be omitted otherwise.
              // allowNewBrowserTab: false
            })
          ])
        })
        .then(results => {
          let dataCollectorInstance = results[0]
          let venmoInstance = results[1]
          this.paypalVenmoDeviceId = dataCollectorInstance.deviceData
          daEventCenter?.triggerNotice?.({
            daId: '2-31-5',
            extraData: {
              payment_method,
              return_status: 'sdkjsapi_return_success'
            }
          })
          if (!this.paypalVenmoDeviceId) {
            this.recordPayLineErr('inline/paypal/initSdk', {
              msg: payment_method + ' 未获取到risk data',
              dataCollectorInstance
            })
          }

          // At this point, you should access the deviceData value and provide it
          // to your server, e.g. by injecting it into your form as a hidden input.
          console.log('Got device data:', dataCollectorInstance.deviceData)

          // Verify browser support before proceeding.
          if (!venmoInstance.isBrowserSupported()) {
            console.log('Browser does not support Venmo')
            this.recordPayLineErr('inline/paypal/initSdk', {
              msg: 'Browser does not support Venmo',
              payment_method
            })
            this.sendErrorReport('paypal_venmo_not_support_sdk', {
              failure_type: 'sdk',
              client_url: '/third/sdk/error',
              description: `venmo SDK初始化失败`,
              paymentActionType: 'venmo_sdk'
            })
            return
          }

          this.displayVenmoButton(venmoInstance)

          // Check if tokenization results already exist. This occurs when your
          // checkout page is relaunched in a new tab. This step can be omitted
          // if allowNewBrowserTab is false.
          if (venmoInstance.hasTokenizationResult()) {
            this.goVenmoPay()
          }
        })
        .catch(err => {
          daEventCenter?.triggerNotice?.({
            daId: '2-31-5',
            extraData: {
              payment_method,
              return_status: 'sdkjsapi_return_failure'
            }
          })
          this.paypalVenmoBtnShow = false
          this.loadingStatus(false, true)
          console.log('paypal ga sdk error: ', err)
          this.recordPayLineErr('inline/paypal/initSdk', {
            msg: '初始化失败',
            payment_method,
            err
          })

          this.sendErrorReport('paypal_venmo_init_sdk_error', {
            failure_type: 'sdk',
            client_url: '/third/sdk/error',
            description: `venmo SDK初始化失败 ${err?.message || ''}`,
            paymentActionType: 'venmo_sdk'
          })

          return null
        })
    },
    venmoPay(order) {
      this.orderData = order || this.orderInfo || {}
      this.goVenmoPay()
    },
    goVenmoPay() {
      if (this.venmoInstance) {
        this.venmoInstance
          .tokenize()
          .then(this.handleVenmoSuccess)
          .catch(this.handleVenmoError)
      }
    },
    displayVenmoButton(venmoInstance = null) {
      this.venmoInstance = venmoInstance
      this.paypalVenmoBtnShow = true
      this.loadingStatus(false, true)
    },
    handleVenmoError(err) {
      let msg = ''
      if (err.code === 'VENMO_CANCELED') {
        msg = 'App is not available or user aborted payment flow'
      } else if (err.code === 'VENMO_APP_CANCELED') {
        msg = 'User canceled payment flow'
      } else {
        msg = err.message
        console.error('An error occurred:', err.message)
      }
      this.recordPayLineErr('inline/paypal/initSdk', {
        msg: `venmo支付失败:${msg}`,
        payment_method: 'PayPal-Venmo'
      })

      if (err.code?.indexOf('CANCELED') === -1) {
        // 曝光请求渠道SDK获取nonce值结果
        daEventCenter?.triggerNotice?.({
          daId: '2-31-4',
          extraData: {
            payment_method: 'PayPal-Venmo',
            return_status: 'nonceapi_return_failure'
          }
        })

        this.sendErrorReport('paypal_venmo_get_nonce_error', {
          failure_type: 'sdk',
          client_url: '/third/sdk/error',
          description: 'PayPal-Venmo获取SDK对应nonce失败',
          paymentActionType: 'venmo_sdk'
        })
      } else {
        this.sendErrorReport('paypal_venmo_sdk_content_error', {
          failure_type: 'sdk',
          client_url: '/third/sdk/error',
          description: 'PayPal-Venmo获取SDK对应nonce失败: ' + msg,
          paymentActionType: 'venmo_sdk'
        })
      }
      if (this.nowPageType == 1) {
        window.location.href =
          host + langPath + '/user/orders/detail/' + this.orderData.billno
      } else if (
        [2, 4, 5].includes(this.nowPageType) ||
        ['XtraDetail'].includes(this.nowCheckoutScene)
      ) {
        this.loadingStatus(false)
      } else if (this.nowPageType == 3) {
        window.location.href = `${langPath}/giftcard/checkout/again/${this.orderData.billno}`
      } else if (['Xtra', 'BuyPrime'].includes(this.nowCheckoutScene)) {
        this.$routerReplace(
          `${langPath}/user/orders/virtualDetail/${this.orderData.billno}`
        )
      }
    },
    handleVenmoSuccess(payload) {
      this.paypalVenmoPayLoad = payload
      console.log('Got a payment method nonce:', payload.nonce)
      console.log('Venmo user:', payload.details.username)

      let order = null
      let pciToken = ''
      let nonceReturnStatus = 'nonceapi_return_success'
      if (!payload?.nonce) {
        nonceReturnStatus = 'nonceapi_return_failure'
      }
      // 曝光请求渠道SDK获取nonce值结果
      daEventCenter?.triggerNotice?.({
        daId: '2-31-4',
        extraData: {
          payment_method: 'PayPal-Venmo',
          return_status: nonceReturnStatus
        }
      })
      if (!payload?.nonce) {
        this.ppBraintreeErrorReport({
          apiScene: 'venmoNonceNull',
          error: { message: 'PayPal-Venmo nonce is null' },
          payment_method: 'PayPal-Venmo'
        })
      }

      this.loadingStatus(true)

      //下单页
      if (
        [1, 3, 4, 6].includes(this.nowPageType) ||
        ['Xtra'].includes(this.nowCheckoutScene)
      ) {
        order = this.orderData
        pciToken = order.jump_pci_token
        let type = ''
        if ([3, 4].includes(this.nowPageType)) type = 'giftcard'
        if (this.nowPageType == 6) type = 'buyprime'
        if (this.nowCheckoutScene == 'Xtra') type = 'xtra'
        window.GB_handlePay(order, {}, this, pciToken, type)

        //订单详情页
      } else if (
        [2, 5].includes(this.nowPageType) ||
        ['XtraDetail'].includes(this.nowCheckoutScene)
      ) {
        order = this.orderData
        const paymentCb = this.paymentCbs(order)[order.payment_method].cb
        paymentCb(null, () => {
          this.loadingStatus(false)
        })
      }
    },

    handleClickSignupOneTimeArrow(paymentCode) {
      this.showPaypalGaType()
      this.signupDrawerConfig = {
        paymentCode,
        show: !this.signupDrawerConfig.show
      }
    },

    getProductType() {
      const typeObj = {
        GiftCard: 'gift_card',
        GiftCardAgain: 'gift_card',
        BuyPrime: 'paid_membership',
        Xtra: 'paid_shein_saver',
        XtraDetail: 'paid_shein_saver'
      }

      if (Object.keys(typeObj).includes(this.nowCheckoutScene)) {
        return typeObj[this.nowCheckoutScene]
      }

      return 'normal'
    },

    sendErrorReport(
      payment_error_scene = '',
      params = {
        failure_type: '',
        client_url: '',
        status_code: '',
        description: '',
        paymentCode: '',
        paymentActionType: 'paypal_sdk'
      }
    ) {
      errorReport.sendPaymentError(payment_error_scene, {
        failure_type: params?.failure_type || '',
        product_type: this.getProductType(),
        payment_action_type: params?.paymentActionType,
        client_url: params?.client_url || '',
        error_data: {
          status_code: params?.status_code,
          uorder_id: this.orderInfo?.relation_billno,
          order_no: this.orderInfo?.billno,
          payment_method: params?.paymentCode,
          description: params?.description || ''
        }
      })
    },
    getPayInlineSessionInfo(paymentCode) {
      return this.payInlineSessionInfo?.[paymentCode] || {}
    },
    resetPayInlineSessionInfo(data) {

      this.payInlineSessionInfo = {
        ...(this.payInlineSessionInfo || {}),
        ...(data || {})
      }
    },
    getPayInlineSessionParams () {
      let amount = 0
      let country = ''
      let orderCurrency = ''

      if (this.nowPageType == 1) {
        amount = this.checkout?.mall_caculate_info?.grandTotalPrice?.amount
        country = this.checkout?.default_address?.value
        orderCurrency = this.now_currency
      } else if (
        [2, 5, 8].includes(this.nowPageType) ||
        ['XtraDetail'].includes(this.nowCheckoutScene)
      ) {
        amount = this.orderInfo?.totalPrice?.amount
        country = this.orderInfo?.shipping_country_code
        orderCurrency = this.orderInfo.currency_code
      } else if (this.nowPageType == 3) {
        amount = this.cardInfo?.sale_price_fromat_price?.amount
        country = this.defaultAddress.value
        orderCurrency = this.nowCurrency

        //礼品卡二次下单页
      } else if (this.nowPageType == 4) {
        amount = this.orderInfo?.currency_total_all
        country = this.orderInfo?.countryCode
        orderCurrency = this.orderInfo.currency_code
        // 会员单独购
      } else if (
        this.nowPageType == 6 ||
        ['Xtra'].includes(this.nowCheckoutScene)
      ) {
        amount = this.primePriceInfo?.paid_total?.amount
        country = this.defaultAddress.value
        orderCurrency = this.nowCurrency
      }
      if (!(amount && country)) {
        !country &&
          this.sendErrorReport('paypal_countryValue_empty', {
            failure_type: 'api',
            client_url: '/api/checkout/channelSession/create',
            description: 'Paypal获取 session 国家信息为空'
          })

        !amount &&
          this.sendErrorReport('paypal_amount_empty', {
            failure_type: 'api',
            client_url: '/api/checkout/channelSession/create',
            description: 'Paypal获取 session 金额信息为空'
          })
      }

      let data = {
        shipCountry: country,
        orderAmount: '' + amount,
        orderCurrency,
        payMethodList: this.needPayInlinePaymentMethods
      }


      if (this.createSessionOrderDetails?.length) {
        data.orderDetails = this.createSessionOrderDetails
      }

      return data
    },
    // 刷新某一个支付方式的inline session
    async refreshPayInlineSession ({ refreshPaymentCode = '' } = {}) {
      if (!refreshPaymentCode) return {}
      const params = this.getPayInlineSessionParams()
      const sessions = await schttp({
        method: 'POST',
        url: '/api/checkout/channelSession/create',
        data: {
          ...params,
          payMethodList: [refreshPaymentCode]
        }
      }).then(res => {
        return res?.info?.sessions || []
      }).catch(() => ({}))

      if (Array.isArray(sessions) && sessions.length) {
        const sessionInfo = sessions?.[0] || {}
        this.resetPayInlineSessionInfo({ [refreshPaymentCode]: sessionInfo })
        return sessionInfo
      }

      return {}
    }
  }
}
