
/**
 * ppga vaulting 选中态
 */
export const PPGA_CHECK_TYPE = {
  'CHECKED': '1',
  'UNCHECKED': '0'
}

/**
 * AfterPay vaulting 选中态
 */
export const AFTERPAY_CHECK_TYPE = {
  'CHECKED': '1',
  'UNCHECKED': '0'
}

/**
 * SignUp 选中态
 */
export const SIGNUP_CHECK_TYPE = {
  'CHECKED': '1',
  'UNCHECKED': '0'
}

// 从 apollo 中获取的地址错误编辑弹窗 [402003, 402004, 402005, 402007]
export const ADDRESS_ERROR_CODE_EDIT_POPUP = typeof window !== 'undefined' ? window?.gbCommonInfo?.ADDRESS_ERROR_CODE_EDIT_POPUP || [] : []

// 下单地址检测 /v2/User/Address/checkAddress 错误码
export const ADDRESS_ERROR_CODE = [ 401996, ...ADDRESS_ERROR_CODE_EDIT_POPUP ]

export const AUTO_RENEWAL_SIGNUP_PAYMENT_METHOD = [
  'PayPal-GApaypal'
]

export const SIGNUP_PAYMENT_METHOD = [
  'PayPal-GApaypal',
  'afterpay-card',
  'afterpay-cashapp',
  'dlocal-nupay',
  'klarna-klarna'
]
